@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,600&display=swap');

:root {}

html body {
  font-family: 'Montserrat';
  font-weight: 400;
  background-color: #fff;
}

/* .banner-text{
  text-align: left;
  font-weight: normal;
  font-size: 40px;
  line-height: normal;
} */

body h2 {
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 70px;
}

.text-uppercase {
  transition: opacity 0.5s ease-in-out;
}

@keyframes blendOut {
  0% {
    opacity: 0;
    /* Stage 1: Start with opacity 0 */
  }

  25% {
    opacity: 0.25;
    /* Stage 2: Opacity increases to 0.25 */
  }

  50% {
    opacity: 0.5;
    /* Stage 3: Opacity increases to 0.5 */
  }

  75% {
    opacity: 0.75;
    /* Stage 4: Opacity increases to 0.75 */
  }

  100% {
    opacity: 1;
    /* Stage 5: Opacity increases to 1 */
  }
}

.Products ul {
  margin: 0;
}

.Products ul.dashed {
  list-style-type: none;
}

.Products ul.dashed>li {
  text-indent: -11px;
}

.Products ul.dashed>li:before {
  content: "-";
  text-indent: -5px;
}

/* Apply animation to the heading when the 'blending-out' class is present */
.animated-heading {
  animation: blendOut 1s ease-out forwards;
}

.d-padding {
  padding-top: 120px;
  padding-bottom: 54px;
}

.def-btn {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  background: rgba(0, 111, 255, 1);
  color: #fff;
  border-radius: 108px;
  padding: 12px 30px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
}

/* .def-btn:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
} */

.grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  list-style: none;
}

.responsive {
  margin-left: 1.5rem;
}

@media (max-width: 375px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 325px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    font-size: 14px;
  }

  .responsive {
    margin-left: 0.75rem;
  }

  .grid-inner h4 {
    font-size: 14px;
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Three columns with equal width */
  gap: 30px;
  /* Gap between grid items */
}

.grid-inner {
  text-align: center;
  background: rgba(197, 241, 250, 1);
  border-radius: 30px;
  padding: 25px;
}

.Benefits .content p {
  margin-bottom: 0;
}

.grid-inner:first-child {
  background: rgba(255, 215, 215, 1);
}

.grid-inner:last-child {
  background: rgba(193, 207, 255, 1);
}

.grid-inner:nth-child(2) {
  background: rgba(255, 237, 193, 1);
}

.grid-inner:nth-child(4) {
  background: rgba(199, 248, 221, 1);
}

.grid-inner:nth-child(5) {
  background: rgba(215, 194, 235, 1);
}

@media (min-width: 1400px) {
  .grid-inner:first-child {
    margin-right: 0px;
  }

  .grid-inner:nth-child(3) {
    margin-left: 0px;
  }

  .grid-inner:nth-child(4) {
    margin-right: 0px;
  }

  .grid-inner:last-child {
    margin-left: 0px;
  }
}

.grid-inner h4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: left;
}

h2::before {
  width: 30px;
  height: 30px;
  opacity: 0.1;
  background: #006FFF;
  position: absolute;
  border-radius: 50%;
  content: '';
  top: -5px;
  margin-left: -5px;
}

.Products ul li {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 20px;
}

.grid-testimonials {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Three columns with equal width */
  gap: 60px;
  /* Gap between grid items */
}

.testimonials-inner {
  max-width: 806px;
  border-radius: 30px;
  box-shadow: 0px 16px 44px 0px rgba(0, 0, 0, 0.07);
  background: rgba(255, 255, 255, 1);
  padding: 50px;
  position: relative;
}

.testimonials-inner::before {
  position: absolute;
  border-radius: 50%;
  content: '';
  background-image: url(../src/assets/t-dot.svg);
  width: 193px;
  height: 180px;
  bottom: -80px;
  left: -90px;
  background-repeat: no-repeat;
  z-index: -1;
}

.grid-testimonials .content {
  margin-left: 20px
}

.grid-testimonials .content h4 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(40, 40, 40, 1);
  margin-bottom: 0;
}

.grid-testimonials .content p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 0;
}

.grid-testimonials .content p span {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left;
  display: block;
}

body .accordion-flush .accordion-item {
  border-radius: 20px;
  border: 1px solid #CCCCCC !important;
  margin-bottom: 20px;
  overflow: hidden;
}

.body .accordion-flush .accordion-item.active {
  border-color: black !important;
  /* Change the border color to black for active accordion items */
}

body .accordion-flush .accordion-item .accordion-button {
  padding: 30px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background: none;
  box-shadow: none;
}

body .accordion-flush .accordion-item .accordion-body {
  padding: 0 30px 30px;
}

footer {
  background-color: rgba(40, 40, 40, 1);
  padding-top: 40px;
  position: absolute;
  /* bottom: 0; */
  width: 100%;
  min-height: auto;
  /* @media (max-width: 768px): {
    position: 'absolute',
    minHeight: 'auto',
    bottom: 0,
  } */
}

footer .first-colum {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three columns with equal width */
  gap: 40px;
  /* Gap between grid items */
}

footer h5 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 15px;
}

footer .first-colum ul li a {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  text-decoration: none;
  margin-bottom: 15px;
  display: block;
}

footer p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

footer a {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  text-decoration: none;
}

.footer-info p,
.footer-info a {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.footer-info {
  border-top: 1px solid rgba(68, 68, 68, 1);
  padding: 20px 0 10px;
  margin-top: 5px;
}

body h1 {
  font-family: Montserrat;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-bottom: 10px;
}

.banner-heading-text {
  font-size: 60px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: 0.01em;
}

.banner-heading-title-text {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -126px;
  color: #282828;
}

.banner-heading-title-text-active {
  font-size: 18px;
}

.banner-heading-title-text::before {
  width: 16px;
  height: 16px;
  opacity: 10%;
  background: #006FFF;
  position: absolute;
  border-radius: 50%;
  content: '';
  top: -5px;
  margin-left: -5px;
}

.banner-section {
  position: absolute;
  bottom: -20px;
  left: 30px;
  padding-bottom: 10px;
}

.container-nav {
  margin: 0px 42px;
  /* border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.navbar-nav .nav-link {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(40, 40, 40, 1);
  padding: 10px 19px !important;
  border-radius: 30px;
  margin: 0 1px;
}

.nav-link.active {
  background: rgba(0, 111, 255, 1);
}

.nav-link:hover {
  border-radius: 30px;
  color: rgba(0, 111, 255, 1);
}

.nav-link:active:hover {
  color: #fff;
}

.mx-auto.navbar-nav {
  backdrop-filter: blur(6px);
  background: rgba(255, 255, 255, 0.4);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
  border-radius: 50px;
  padding: 3px;
}

.navbar-dark.bg-transparent {
  width: 100%;
  z-index: 100;
  position: fixed;
  backdrop-filter: blur(8px);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 0) 100%);
  padding: 12px 0px 8px 0px;
  top: 0
}

.footer-info ul li a {
  padding: 0 10px;
}

.footer-info ul li a:last-child {
  padding-right: 0;
}

.offcanvas-backdrop {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.accordion-header::before {
  display: none;
}

.top-banner {
  margin: 24px;
  margin-top: 110px;
}

.top-banner-about {
  margin: 24px;
  margin-top: 200px;
}

@media (max-width: 1750px) {
  body h1 {
    font-size: 36px;
    line-height: 72px;
  }
}

@media (max-width: 1600px) {
  body h1 {
    font-size: 32px;
    line-height: 64px;
  }
}

@media (max-width: 1440px) {
  .navbar-nav .nav-link {
    padding: 10px 14px !important
  }

  body h1 {
    font-size: 28px;
    line-height: 52px;
  }

  .contact-us-form {
    padding-left: 0px;
    margin-left: 0px;
  }
}

@media (min-width: 1200px) {
  .top-banner::before {
    /* background-image: url(./assets/Shap-1.svg); */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 245px;
    height: 149px;
    background-repeat: no-repeat;
  }

  .top-banner::after {
    /* background-image: url(./assets/Shap-2.svg); */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 411px;
    height: 149px;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1199px) {
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    /* Adjust as needed */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Example shadow */
    z-index: 1000;
    /* Ensure it's above other content */
  }

  body .navbar-toggler {
    padding: 0;
  }

  /* .navbar-dark.bg-transparent {
    position: static;
  } */

  body h1 {
    font-size: 16px;
    line-height: 26px;
  }

  .container {
    max-width: 100% !important;
  }

  .testimonials .heading-sec,
  .testimonials .testimonials-inner {
    max-width: 100% !important;
    width: 100% !important;
    text-align: center;
  }

  .testimonials .d-flex.flex-wrap {
    flex-direction: column;
  }

  .testimonials .heading-sec {
    padding: 0 0 10px !important;
  }

  .testimonials .heading-sec h2 {
    text-align: center !important;
  }

  .offcanvas-header,
  .offcanvas-body {
    background: linear-gradient(167.98deg, rgba(40, 40, 40, 0.4) 0%, rgba(40, 40, 40, 0.4) 100%);
  }

  .offcanvas-body .nav-link {
    color: #fff;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.01em;
    padding-left: 0;
  }

  .offcanvas-body .nav-link svg {
    margin-right: 10px;
  }

  .offcanvas-body .d-flex.justify-content-center.mt-3 {
    justify-content: flex-start !important;
    margin-top: 20px;
  }

  .offcanvas-body button.rounded-pill.mx-2.border.border-dark.text-dark.btn.btn-outline-light {
    color: #fff !important;
    border: 1px solid #fff !important;
  }
}

@media (max-width: 1024px) {
  body h1 {
    font-size: 18px;
    line-height: 32px;
  }

  .banner-section {
    left: 12px;
  }

  body h2 {
    margin-bottom: 40px;
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
  }

  .d-padding {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  footer .first-colum,
  .sec-colum.w-50.grid-2 {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .top-banner-about {
    margin: 24px;
    margin-top: 100px;
  }

  .banner-heading-text {
    font-size: 30px;
    line-height: 56px;
  }

  .contact-us-form {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .Products {
    max-width: 600px;
    margin: auto;
  }

  .Products img {
    width: 100%;
  }

  .Products .d-flex {
    display: inline !important;
  }

  /* .top-banner img {
    height: 400px;
  } */

  .banner-section {
    left: 6px;
    bottom: -26px;
  }
}


@media (max-width: 768px) {
  .d-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .top-banner-about {
    margin-top: 60px;
  }

  .testimonials-page-client-name {
    font-size: 16px;
  }

  body h1 {
    font-size: 14px;
    line-height: 26px;
  }
}

@media (max-width: 767px) {

  .d-padding {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .footer-info .d-flex.justify-content-between.flex-wrap {
    justify-content: center !important;
  }

  .testimonials-inner {
    padding: 30px 20px;
  }

  .grid-testimonials {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

  body .accordion-flush .accordion-item .accordion-button {
    font-size: 16px;
    padding: 20px;
  }

  body .accordion-flush .accordion-item .accordion-body {
    padding: 0 20px 20px;
  }

  .footer-info p {
    text-align: center;
  }

  .first-colum .logo {
    grid-column: 1 / span 3;
    display: flex;
  }

  body h1 {
    font-size: 14px;
    line-height: 32px;
  }

  body .offcanvas.offcanvas-end {
    width: 100%;
  }

  .top-banner .desktop {
    display: none;
  }

  .top-banner .mobile {
    display: block !important;
  }

  .top-banner::after {
    /* background-image: url(./assets/Shap-3.svg); */
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 212px;
    height: 96px;
  }

  .banner-section {
    left: 12px;
    z-index: 1;
    bottom: -26px;
  }

  .top-banner {
    margin: 12px;
    margin-top: 130px;
  }

  body .btn-close {
    --bs-btn-close-color: #fff;
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e);
    opacity: 1;
  }

  .first-colum .logo h5 {
    margin: 0 20px 0 66px;
  }

  footer .first-colum {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 540px) {
  body h1 {
    font-size: 10px;
    line-height: 21px;
  }

  .banner-section .def-btn {
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
    padding: 12px 15px;
  }

  .grid-2 {
    display: unset;
  }
}

.custom-popover {
  position: relative;
}

.popoverClose {
  position: absolute;
  top: -10px;
  right: -10px;
}

.custom-popover .popover {
  border-radius: 10px;
}

.custom-popover .popover-body {
  padding: 0;
}

.custom-popover-content {
  background-color: #FFEDC1;
  padding: 10px;
  border-radius: 10px;
  color: #333;
}


.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.popover-header {
  background-color: #FFEDC1;
}

/*Harshvardhan CSS*/
.login-button {
  background-color: transparent;
  transition: all 0.3s ease;
  font-weight: 600;
  color: black;
  border: 2px solid black;
  padding: 8px;
  padding-right: 12px;
  padding-left: 12px;
  width: 110px;
}

.login-button:hover {
  background-color: black;
  border: 2px solid black;
  color: white;
}

.login-button-mobile {
  background-color: transparent;
  transition: all 0.3s ease;
  font-weight: 600;
  color: white;
  border: 2px solid white;
  width: 131px;
}

.login-button-.mobile {
  background-color: white;
  border: 2px solid white;
  color: black;
}

.sign-up-button {
  transition: all 0.3s ease;
  font-weight: 600;
  padding: 12px 30px;
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .login-button {
    width: 80px;
    font-size: 12px;
  }

  .sign-up-button {
    /* width: 80px; */
    font-size: 12px;
    font-weight: 600;
  }
}

.offcanvas.show .nav-link.active {
  background-color: transparent !important;
}

.form-control {
  border: 1px solid #CCCCCC;
  border-radius: 30px;
  padding: 16px;
}

.top-banner-other-pages {
  margin-top: 130px;
  overflow: hidden;
}

.contact-us-form {
  /* background-color: rgba(178, 14, 15, 1); */
  /* background-color: #42494F; */
  padding: 50px;
  border-radius: 30px;
  z-index: 0;
  /* padding-left: 100px; */
  /* margin-left: 200px; */
  border-color: #E9E9E9;
}

.container-get-in-touch {
  max-width: 1093px;
  position: relative;
}

.get-in-touch {
  box-shadow: 0px 16px 44px 0px rgba(0, 0, 0, 0.07);
  background-color: #FFFFFF;
  border-radius: 30px;
  padding: 30px;
  width: 360px;
  left: -68px;
  top: 16%;
  position: absolute;
}

@media (max-width: 1024px) {
  .contact-us-form {
    margin-top: 20px;
    margin-left: 300px;
  }

  .get-in-touch {
    left: 20px;
    top: 18%;
  }
}

@media (max-width: 768px) {
  .contact-us-form {
    margin-top: 20px;
    padding-left: 50px;
    margin-left: 0px;
  }

  .get-in-touch {
    position: unset;
    width: 744px;
  }

  .view-all-products {
    text-align: center;
  }
}

ul.dashed {
  list-style-type: none;
}

ul.dashed a {
  color: #282828;
  text-decoration: none;
}

ul.dashed a:hover {
  font-weight: bold;
  transition: font-weight 0.3s ease;
  color: rgba(0, 111, 255, 1);
}

ul.dashed li {
  min-width: 310px;
}

ul.dashed>li {
  text-indent: -20px;
}

ul.dashed>li:before {
  content: "-";
  text-indent: -20px;
}

.grid-testimonial-page {
  /* display: grid; */
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Set the initial max width to 250px */
  list-style: none;
}

.grid-inner-testimonial-page {
  margin-top: 14px;
  padding: 30px;
  display: grid;
  border-radius: 30px;
  box-shadow: 0px 16px 22px 0px rgba(0, 0, 0, 0.07)
}

@media (min-width: 576px) {
  .grid-testimonial-page {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Adjust for small devices */
  }
}

@media (min-width: 768px) {
  .grid-testimonial-page {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* Adjust for medium devices */
  }
}

@media (min-width: 992px) {
  .grid-testimonial-page {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    /* Adjust for large devices */
  }
}

@media (min-width: 1200px) {
  .grid-testimonial-page {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    /* Adjust for extra large devices */
  }
}

.grid-inner-testimonial-page .content {
  width: 100%;
  /* Ensure content width fills the container */
}

.grid-inner-testimonial-page .content p {
  margin-bottom: 0;
  /* Remove default margin to prevent overflow */
}

.other-pages-body-text {
  font-size: 18px;
  line-height: 30px;
}

.other-pages-body-text li {
  font-size: 18px;
}

.content a {
  color: #282828;
  text-decoration: none;
}

.content a:hover {
  color: rgba(0, 111, 255, 1);
}

.modal-content {
  border-radius: 30px;
}

.load-more:hover {
  font-weight: 600;
  cursor: pointer;
}

.contact-us-claims {
  text-decoration: underline;
  z-index: 1000;
}

.contact-us-claims:hover {
  font-weight: bold;
}

.highlight {
  position: relative;
}

.highlight h3 {
  color: rgba(0, 111, 255, 1);
}

.highlight ::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 0.5px dashed rgba(0, 111, 255, 1);
  height: 100%;
}

.about-highlight {
  color: rgba(0, 111, 255, 1);
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ul-list {
  list-style-type: none;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 1px 10px 1px rgba(0,0,0,.2);
  display: inline-flex;
  padding: 0;
  border-radius: 108px;
}

.li-list {
  float: left;
}

.li-list {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #000000;
  border-radius: 108px;
  padding: 12px 14px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
}

.selected {
  background-color: rgb(1, 84, 192);
  color: #fff !important;
}